<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t("sidebar.generate_report") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors2.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors2"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.custom_report") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="totalType"
                        @change="chgReportType($event)"
                      >
                        <option value="" selected>
                          {{ $t("form.choose_type") }}
                        </option>
                        <option value="company">
                          {{ $t("form.company") }}
                        </option>
                        <option value="trader">
                          {{ $t("form.trader") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6" v-if="totalType">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.choose")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="totalId"
                      >
                        <option value="0" selected>
                          {{ $t("form.choose") }}
                        </option>
                        <option
                          v-for="(item, index) in coms_trads"
                          :value="item.id"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.from')"
                      :placeholder="$t('form.from')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.from_date"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.to')"
                      :placeholder="$t('form.to')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.to_date"
                    />
                  </div>
                </div>
              </div>
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.generate_report')"
                @click.prevent="generateCustomReport"
              />
            </form>
            <hr />
            <form>
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.total_report") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="totalType"
                        @change="chgReportType($event)"
                      >
                        <option value="" selected>
                          {{ $t("form.choose_type") }}
                        </option>
                        <option value="company">
                          {{ $t("form.company") }}
                        </option>
                        <option value="trader">
                          {{ $t("form.trader") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6" v-if="totalType">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.choose")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="totalId"
                      >
                        <option value="0" selected>
                          {{ $t("form.choose") }}
                        </option>
                        <option
                          v-for="(item, index) in coms_trads"
                          :value="item.id"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.generate_report')"
                @click.prevent="generateTotalReport"
              />
            </form>
          </card>
        </div>
      </div>
      <div class="row mt-4" v-if="customReport.length > 0">
        <div class="col">
          <div
            class="card shadow"
            :class="type === 'dark' ? 'bg-default' : 'bg-default'"
          >
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-default' : 'bg-default'"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3
                    class="mb-0"
                    :class="type === 'dark' ? 'text-white' : 'text-white'"
                  >
                    {{ $t("tables.custom_report") }}
                  </h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : 'table-dark'"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-dark'"
                tbody-classes="list"
                :data="customReport"
              >
                <!-- Table Head -->
                <template v-slot:columns>
                  <th>{{ $t("tables.sequence") }}</th>
                  <th>{{ $t("tables.order_price") }}</th>
                  <th>{{ $t("tables.order_date") }}</th>
                  <th>{{ $t("tables.action") }}</th>
                </template>
                <!-- Table Body -->
                <template v-slot:default="row">
                  <td class="budget">
                    {{ row.item.sequence }}
                  </td>
                  <td class="budget">
                    {{ row.item.order_price }}
                  </td>
                  <td class="budget">
                    {{ row.item.order_date }}
                  </td>
                  <td class="budget"></td>
                </template>
              </base-table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4" v-if="totalReport.length > 0">
        <div class="col">
          <div
            class="card shadow"
            :class="type === 'dark' ? 'bg-default' : 'bg-default'"
          >
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-default' : 'bg-default'"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3
                    class="mb-0"
                    :class="type === 'dark' ? 'text-white' : 'text-white'"
                  >
                    {{ $t("tables.total_report") }}
                  </h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : 'table-dark'"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-dark'"
                tbody-classes="list"
                :data="totalReport"
              >
                <!-- Table Head -->
                <template v-slot:columns>
                  <th>{{ $t("tables.total_invoices") }}</th>
                  <th>{{ $t("tables.total_cash") }}</th>
                  <th>{{ $t("tables.total_months") }}</th>
                  <th>{{ $t("tables.total_weeks") }}</th>
                  <th>{{ $t("tables.total_days") }}</th>
                </template>

                <!-- Table Body -->
                <template v-slot:default="row">
                  <td class="budget">
                    {{ row.item.total_invoices }}
                  </td>
                  <td class="budget">
                    {{ row.item.total_cach }}
                  </td>
                  <td class="budget">
                    {{ row.item.total_months }}
                  </td>
                  <td class="budget">
                    {{ row.item.total_weeks }}
                  </td>
                  <td class="budget">
                    {{ row.item.total_days }}
                  </td>
                </template>
              </base-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Generate-Report",
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      validationErrors2: [],
      totalType: "",
      totalId: "0",
      model: {
        from_date: "",
        to_date: "",
      },
      coms_trads: [],
      totalReport: [],
      customReport: [],
    };
  },
  methods: {
    // Change Report Type
    chgReportType: function () {
      // this.loading = true;
      if (event.target.value == "company" || event.target.value == "trader") {
        this.getArr(
          `admin/administration/${
            event.target.value == "company" ? "companies" : "traders"
          }/index`
        );
      }
      this.totalType = event.target.value;
    },
    getArr: function ($url) {
      this.loading = true;
      axios
        .get($url)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.coms_trads = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    generateCustomReport: function () {
      this.loading = true;
      this.totalReport = [];
      this.validationErrors2 = [];
      axios
        .post(
          "admin/administration/reports/modelInvoicesGenerate",
          this.toFormData("custom")
        )
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.customReport = response.data.data;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.validationErrors2.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    generateTotalReport: function () {
      this.loading = true;
      this.customReport = [];
      this.validationErrors = [];
      axios
        .post(
          "admin/administration/reports/modelInvoicesGenerateTotal",
          this.toFormData()
        )
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.totalReport = response.data.data;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Send Date to get Report
    sendDatestoGenerate: function () {
      if (this.model.from_date.length > 0 && this.model.to_date.length > 0) {
        this.$router.push({
          name: "get traders report",
          params: {
            dates: [this.model.from_date, this.model.to_date],
          },
        });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          text: `${this.$t("validation.all fields required")}`,
        });
      }
    },
    toFormData: function ($type = null) {
      var fd = new FormData();
      fd.append("type", this.totalType);
      if (this.totalType == "company") {
        fd.append("company_id", this.totalId);
      } else {
        fd.append("trader_id", this.totalId);
      }
      if ($type == "custom") {
        fd.append("from_date", this.model.from_date);
        fd.append("to_date", this.model.to_date);
      }
      return fd;
    },
  },
};
</script>
